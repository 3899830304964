html{
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
section{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 100px 0;
  margin-inline: auto;
}
section.section-no-pad{
  padding: 0;
}
section.section-blocks{
  align-items: start;
  justify-content: start;
  flex-direction: row;
  flex-wrap: wrap;
}
h1,
a.fp-title{
  color: #333;
  font-size: 5em;
  font-weight: 300;
  text-align: center;
  margin: 0;
  padding: 0;
  text-decoration: none;
}
a.fp-title:hover{
  text-decoration: underline;
}
a.mini-link-title{
  color: #333;
  font-size: 3em;
  font-weight: 300;
  text-align: center;
  margin: 0;
  padding: 50px;
  text-decoration: none;
}
a.mini-link-title:hover{
  text-decoration: underline;
}
.viewheight-centered-block{
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
a.achievement{
  position: relative;
  display: flex;
  text-decoration: none;
  color: #333;
  padding: 20px;
  flex-direction: column;
  width: 46%;
  height: 250px;
  background-size: cover;
  background-repeat: no-repeat;
}
a.achievement:only-child{
  border-radius: 20px;
  width: 100%;
}
a.achievement::before{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8); /* black background with 50% opacity */
}
a.achievement:hover{
  filter: brightness(0.9);
}
a.achievement h2,
a.achievement p{
  z-index: 1;
}
a.achievement h2{
  margin: 0;
  color: white;
}
a.achievement .grey-text{
  margin-top: 5px;
}
.pills{
  display: flex;
  gap: 5px;
}
.pill{
  background-color: #333;
  color: white;
  padding: 5px 10px;
  border-radius: 20px;
  margin: 0;
  width: fit-content;
  font-size: 0.75em;
}
.grey-text{
  color: rgb(186, 186, 186);
}
.achievement-title-wrapper:hover .achievement-title-wrapper-text{
  visibility: visible;
  opacity: 1;
}
.work-in-progress-banner{
  position: absolute;
  right: 0;
  top: 0;
  background-color: rgb(137, 0, 0);
  height: fit-content;
  display: flex;
  align-items: center;
  padding: 20px 50px;
  font-size: 1em;
  color: white;
  font-weight: 800;
}
